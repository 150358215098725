@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body,
html {
  height: 100%;
  background: linear-gradient(270deg, #02266f, #0055dd);
  animation: gradientAnimation 15s ease infinite;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.full-page-background {
  height: 100%;
  padding: 2em 2em;
  background-size: 400% 400%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
  color: #fff;
}

.App-logo {
  width: 200px;
  pointer-events: none;
  border-radius: 25px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.callBtn {
  transition: 400ms cubic-bezier(0.83, 0.3, 0.61, 0.88);
}

.callBtn:hover {
  /* transform: scale(1.15);
   */
  margin-top: 0.25em;
}
